import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { EMPTY_CHARGE_DATA_SEARCH_CRITERIA } from "presentation/constant/ChargeDataEnquiry/ChargeDataSearchCriteria";
import { DocumentEnquiryCriteria } from "presentation/constant/DocumentEnquiry/DocumentEnquiryCriteria";
import { useDocumentEnquiryVM } from "presentation/hook/DocumentEnquiry/useDocumentEnquiryVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useDocumentEnquiryTracked } from "presentation/store/DocumentEnquiry/DocumentEnquiryProvider";
import { useCallback, useEffect, useState } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { isSearchCriteriaSet } from "../../DocumentEnquiryComp";
import { DocumentEnquiryCriteriaEditChargePanel } from "./DocumentEnquiryCriteriaEditPanel/DocumentEnquiryCriteriaEditChargePanel";
import { DocumentEnquiryCriteriaEditDocumentPanel } from "./DocumentEnquiryCriteriaEditPanel/DocumentEnquiryCriteriaEditDocumentPanel";
import { DocumentEnquiryCriteriaEditTariffCoaPanel } from "./DocumentEnquiryCriteriaEditPanel/DocumentEnquiryCriteriaEditTariffCoaPanel";
import { DocumentEnquiryCriteriaEditVoyagePanel } from "./DocumentEnquiryCriteriaEditPanel/DocumentEnquiryCriteriaEditVoyagePanel";
import { DocumentEnquiryCriteriaPreviewChargePanel } from "./DocumentEnquiryCriteriaPreviewPanel/DocumentEnquiryCriteriaPreviewChargePanel";
import { DocumentEnquiryCriteriaPreviewDocumentPanel } from "./DocumentEnquiryCriteriaPreviewPanel/DocumentEnquiryCriteriaPreviewDocumentPanel";
import { DocumentEnquiryCriteriaPreviewTariffCoaPanel } from "./DocumentEnquiryCriteriaPreviewPanel/DocumentEnquiryCriteriaPreviewTariffCoaPanel";
import { DocumentEnquiryCriteriaPreviewVoyagePanel } from "./DocumentEnquiryCriteriaPreviewPanel/DocumentEnquiryCriteriaPreviewVoyagePanel";

export const DocumentEnquiryCriteriaPanel = () => {
    const [docEnquiryState] = useDocumentEnquiryTracked();
    const docEnquiryVM = useDocumentEnquiryVM();
    const isEditSearchCriteria = docEnquiryState.isEditSearchCriteria;
    const searchCriteria = docEnquiryState.searchCriteria;
    const [isLoading, setIsLoading] = useState(false);
    const messageBarVM = useMessageBarVM();
    const [isShow, setIsShow] = useState<boolean>(false);
    const msg = 'Fill in at least one of the search criteria.';


    const handleResetClick = () => {
        docEnquiryVM.onRemoveAllSearchCriteria();
    }

    useEffect(() => {
        const checkSearchCriteria = async () => {
            if (!docEnquiryVM.validateSearchCriteria(searchCriteria)) {
                setIsShow(false);
            }
        }
        checkSearchCriteria();

    }, [docEnquiryVM, searchCriteria])

    const handleSearchClick = useCallback(async (searchCriteria: DocumentEnquiryCriteria, counter: number) => {
        if (docEnquiryVM.validateSearchCriteria(searchCriteria)) {
            setIsShow(true);
            messageBarVM.showWarining("Fill in at least one of the search criteria.");
            return;
        }
        setIsLoading(true);
        docEnquiryVM.onSearch();

        await docEnquiryVM.fetchTableData(searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then((data) => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
        }).finally(() => {
        })
    }, [docEnquiryVM, messageBarVM]);

    useEffect(() => {
        let timer: any = null;
        const cb = () => {
            const comField: any = document.querySelector('.im-charge-data-search-criteria-content input[name="co"]');
            if (comField) {
                comField.focus();
                timer && clearInterval(timer);
            }
        };

        timer = setInterval(cb, 0);
    }, []);

    return <div className='im-charge-data-search-criteria-panel'>
        <div className='im-charge-data-search-criteria-content'>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            {/* Search criteria form */}
            {
                isEditSearchCriteria &&
                <>
                    <DocumentEnquiryCriteriaEditVoyagePanel />
                    <DocumentEnquiryCriteriaEditChargePanel />
                    <DocumentEnquiryCriteriaEditTariffCoaPanel />
                    {/* <DocumentEnquiryCriteriaEditContainerPanel /> */}
                    <DocumentEnquiryCriteriaEditDocumentPanel />
                </>
            }

            {/* Search criteria preview panel */}
            {
                !isEditSearchCriteria && !isSearchCriteriaSet(docEnquiryState.searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA) &&
                <>
                    <DocumentEnquiryCriteriaPreviewVoyagePanel />
                    <DocumentEnquiryCriteriaPreviewChargePanel />
                    <DocumentEnquiryCriteriaPreviewTariffCoaPanel />
                    {/* <DocumentEnquiryCriteriaPreviewContainerPanel /> */}
                    <DocumentEnquiryCriteriaPreviewDocumentPanel />
                </>
            }
            {/* Search criteria no criteria selected */}
            {!isEditSearchCriteria && isSearchCriteriaSet(docEnquiryState.searchCriteria, EMPTY_CHARGE_DATA_SEARCH_CRITERIA) && <h4 style={{ margin: '0px' }}>No Criteria.</h4>}
        </div>
        <div className='im-charge-data-search-criteria-buttons'>
            <>
                {isEditSearchCriteria && <><label style={{ fontSize: '12px', color: 'red' }}>{(isShow) && msg}</label>
                    <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={handleResetClick} /></>}
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={(e: any) => {
                    handleSearchClick(searchCriteria, docEnquiryState.searchCounter);
                }} />
            </>
        </div>
    </div>
};
